import React from 'react';
import Button from '../../button';

class ListItem extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const props = this.props
    const item = props.item
    const attributes = item.attributes_hash || {}
    const playerUrl = this.props.playerUrl
    const selected = props.current ? 'selected' : ''
    const className = 'list-item library show ' + selected

    return (
      <li className={className} onClick={props.handleSelect}>
        <div className='image-frame center'>
          {playerUrl && this.props.playerButton(item)}
        </div>
        <div className='main'>
          <h3 className='name'>
            {attributes.name}
          </h3>
          {attributes.description &&
            <div className='description'>
              {attributes.description}
            </div>
          }
        </div>
      </li>
    )
  }
}

export default ListItem;
