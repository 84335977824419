import React from 'react';
import ReactPlayer from 'react-player'
import Button from '../button';
import Fetch from './list/fetch'
import ListItem from './audio_album/list_item'
import {mediaUrl} from '../base/functions'

const AjaxAPI = window.AjaxAPI;

class SettingsFormProps extends React.Component {
  constructor(props) {
    super(props);

    this.updateSettingsFormProps()
  }

  componentDidUpdate(prevProps) {
    this.updateSettingsFormProps()
  }

  updateSettingsFormProps () {
    const signals = this.props.namedSignals[this.props.albumListKey]
    const albums = (signals && signals.list) || []
    const formProps = this.props.namedSignals.settingsFormProps || {}
    const fields = {
      album_id: {
        options: [{
          label: 'None selected',
          value: null
        }]
      },
      layout: {
        options: [
          { label: 'Background Cover', value: 'background-cover' },
          { label: 'Thumbnail Cover', value: 'thumbnail-cover' }
        ]
      }
    }

    albums.forEach(function(album, i) {
      fields.album_id.options.push({
        label: album.attributes_hash.name,
        value: album.id
      })
    })

    if (JSON.stringify(formProps.audio_album) === JSON.stringify(fields)) {
      return
    }

    this.props.setNamedSignals([
      { key: 'settingsFormProps', signal: 'audio_album', data: fields }
    ])
  }

  render() { return(null) }
}

class AudioAlbumBlock extends React.Component {
  constructor(props) {
    super(props);

    this.albumListKey = '/block_data/album?data_type=audio'
    this.settingsKey = '/block_data/album?data_type=audio'

    this.togglePlayer = this.togglePlayer.bind(this);
    this.playerButton = this.playerButton.bind(this);
    this.onEnded = this.onEnded.bind(this);

    this.state = {
      play: null,
      playlist: []
    }
  }

  componentDidUpdate(prevProps) {
    const albumId = this.props.block.instance.attributes_hash.album_id
    const signals = this.props.namedSignals['currentAlbum']
    const currentAlbum = (signals && signals.audio) || {}

    if (this.state.play && (albumId !== currentAlbum.id)) {
      this.setState({ play: null })
    }

    this.setCurrentPlaylist();
  }

  setCurrentPlaylist() {
    const signals = this.props.namedSignals['audioPlayer'];
    const activePlayer = signals && signals.active;
    const playlist = signals && signals.playlist;
    const attributes_hash = this.props.block.instance.attributes_hash;
    const autoUpdatePlaylist = attributes_hash && attributes_hash.auto_update_playlist;

    if (activePlayer && !playlist && autoUpdatePlaylist) {
      const album = this.thisAlbum() || {}
      const collection = album.collection;
      if (collection && collection[0]){
        this.setCurrentItem(collection[0], false);
      }
    }
  }

  setCurrentItem(item, play = true) {
    const signals = this.props.namedSignals['audioPlayer'];
    const activePlayer = signals && signals.active;
    const playlist = this.thisAlbum().collection;
    const coverUrl = this.coverUrl(item) || this.coverUrl(this.thisAlbum());

    playlist.forEach(function(i) {
      i.coverUrl = coverUrl;
    });

    if (activePlayer) {
      console.log('setCurrentItem', 'play', play)
      item.coverUrl = coverUrl;
      this.props.setNamedSignals([
        { key: 'audioPlayer', signal: 'item', data: item },
        { key: 'audioPlayer', signal: 'play', data: play },
        { key: 'audioPlayer', signal: 'playlist', data: playlist }
      ])
    } else {
      this.setState({ play: item })
    }
  }

  updateCurrentAlbum (item) {
    const _this = this
    const albumId = this.props.block.instance.attributes_hash.album_id
    const signals = this.props.namedSignals['currentAlbum']
    const album = (signals && signals.audio) || {}

    if (albumId && (albumId != album.id)) {
      (new AjaxAPI).get('/block_data/album/'+albumId, function(data){
        const album = data.data
        const collection = album.collection || []
        _this.setState({playlist: collection})
        _this.props.setNamedSignals([
          { key: 'currentAlbum', signal: 'audio', data: album }
        ])
        _this.setCurrentItem(item)
      })
    }
  }

  togglePlayer(e, item) {
    const albumId = this.props.block.instance.attributes_hash.album_id
    const signals = this.props.namedSignals['currentAlbum']
    const currentAlbum = (signals && signals.audio) || {}

    e.stopPropagation()

    if ((item && item.id) === (this.state.play && this.state.play.id)) {
      this.setState({ play: null })
    } else {
      if (albumId !== currentAlbum.id) {
        this.updateCurrentAlbum(item)
      } else {
        this.setCurrentItem(item)
      }
    }
  }

  playerButton(item) {
    const play = this.state.play && (item.id === this.state.play.id)
    return (
      <Button
        icon={play ? 'pause' : 'play'}
        label={play ? 'Pause' : 'Play'}
        handleClick={(e) => { this.togglePlayer(e,item) }}
        buttonClass='btn inline square'
      />
    )
  }

  next() {
    const state = this.state
    var next = null;
    if (!state.play) {
      next = state.playlist[0]
    } else {
      state.playlist.forEach(function(item, i){
        if (item.id === state.play.id) {
          next = state.playlist[i+1]
        }
      })
    }
    return next
  }

  onPause() {
    //console.log('onPause')
  }

  onEnded() {
    //console.log('Ended', this.state.play)

    this.setState({ play: this.next() })
  }

  onProgress = state => {
    //console.log('onProgress', state)
  }

  playerUrl(item) {
    const attributes = item.attributes_hash || {}

    if (attributes.file) {
      return mediaUrl(
        [this.props.user.cdn, '/files/', item.data_type, '/', attributes.file]
      )
    }

    return null
  }

  coverUrl(item) {
    if (!item) {return null}

    const attributes = item.attributes_hash || {}
    if (attributes.cover) {
      return mediaUrl(
        [this.props.user.cdn, '/files/image/', attributes.cover]
      )
    }
    return null
  }

  thisAlbum() {
    const albumId = this.props.block.instance.attributes_hash.album_id
    const signals = this.props.namedSignals[this.albumListKey]
    const list = (signals && signals.list) || []

    const album = list.find(function(item) {
      return item.id === albumId
    });
    return album;
  }

  render() {
    const props = this.props
    const block_attributes = props.block.instance.attributes_hash
    const album = this.thisAlbum() || {}
    const album_attributes = album.attributes_hash || {}
    const coverUrl = this.coverUrl(album)
    const collection = album.collection || []
    const play = this.state.play
    const playerUrl = play && this.playerUrl(play)
    const layouts = {}

    layouts['background-cover'] = (
      <div className='audio-album square'>
        <div className='cover' style={coverUrl && { backgroundImage : `url(${coverUrl})` }}>
          <div className='info'>
            <h2 className='title'>
              {album_attributes.name}
            </h2>
            <div className='description'>
              {album_attributes.description}
            </div>
          </div>
          <div className='list'>
            {collection.map((o) =>
              <ListItem
                key={o.id}
                item={o}
                playerButton={this.playerButton}
                handleSelect={(e) => { this.togglePlayer(e,o) }}
                playerUrl={this.playerUrl(o)}
                current={play && (o.id === play.id)}
              />
            )}
          </div>
        </div>
      </div>
    )

    layouts['thumbnail-cover'] = (
      <div className='audio-album thumbnail-cover'>
        <div className='cover'>
          <img src={coverUrl} />
        </div>
        <div className='info'>
          <h2 className='title'>
            {album_attributes.name}
          </h2>
          <div className='description'>
            {album_attributes.description}
          </div>
        </div>
        <div className='list'>
          {collection.map((o) =>
            <ListItem
              key={o.id}
              item={o}
              playerButton={this.playerButton}
              handleSelect={(e) => { this.togglePlayer(e,o) }}
              playerUrl={this.playerUrl(o)}
              current={play && (o.id === play.id)}
            />
          )}
        </div>
      </div>
    )

    return (
      <React.Fragment>
        {layouts[block_attributes.layout || 'background-cover']}
        {playerUrl &&
          <ReactPlayer
            url={playerUrl}
            playing={!!play}
            config={{ file: { forceAudio: true }}}
            onReady={() => console.log('onReady')}
            onStart={() => console.log('onStart')}
            onPlay={() => console.log('onPlay')}
            onPause={this.onPause}
            onEnded={this.onEnded}
            onProgress={this.onProgress}
            width='0px'
            height='0px'
          />
        }
        <Fetch {...props}
          signalsKey={this.albumListKey}
        />
        {this.props.edit &&
          <SettingsFormProps {...props}
            albumListKey={this.albumListKey}
            settingsKey={this.settingsKey}
          />
        }
      </React.Fragment>
    )
  }
}

export default AudioAlbumBlock;
